export type HolidayType =
  | 'NEUJAHRSTAG'
  | 'HEILIGEDREIKOENIGE'
  | 'KARFREITAG'
  | 'OSTERSONNTAG'
  | 'OSTERMONTAG'
  | 'TAG_DER_ARBEIT'
  | 'CHRISTIHIMMELFAHRT'
  | 'MARIAHIMMELFAHRT'
  | 'PFINGSTSONNTAG'
  | 'PFINGSTMONTAG'
  | 'FRONLEICHNAM'
  | 'DEUTSCHEEINHEIT'
  | 'REFORMATIONSTAG'
  | 'ALLERHEILIGEN'
  | 'BUBETAG'
  | 'ERSTERWEIHNACHTSFEIERTAG'
  | 'ZWEITERWEIHNACHTSFEIERTAG'
  | 'WELTKINDERTAG'
  | 'WELTFRAUENTAG'
  | 'AUGSBURGER_FRIEDENSFEST'
  ;

export const allHolidays: HolidayType[] = [
  'NEUJAHRSTAG',
  'HEILIGEDREIKOENIGE',
  'KARFREITAG',
  'OSTERSONNTAG',
  'OSTERMONTAG',
  'TAG_DER_ARBEIT',
  'CHRISTIHIMMELFAHRT',
  'MARIAHIMMELFAHRT',
  'PFINGSTSONNTAG',
  'PFINGSTMONTAG',
  'FRONLEICHNAM',
  'DEUTSCHEEINHEIT',
  'REFORMATIONSTAG',
  'ALLERHEILIGEN',
  'BUBETAG',
  'ERSTERWEIHNACHTSFEIERTAG',
  'ZWEITERWEIHNACHTSFEIERTAG',
  'WELTKINDERTAG',
  'WELTFRAUENTAG',
  'AUGSBURGER_FRIEDENSFEST',
];
